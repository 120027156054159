import {useEffect, useState} from 'react'
import ReactGA from 'react-ga4'
import {Cookies, getCookieConsentValue} from 'react-cookie-consent'

import {initGA} from '../utils/analytics.js'

export default function useTracking() {
  const [isConsent, setIsConsent] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const isConsent = getCookieConsentValue()
    setIsConsent(isConsent === 'true')
  }, [])

  useEffect(() => {
    isConsent && initialize()
  }, [isConsent])

  const untrack = () => {
    Cookies.remove('_ga')
    Cookies.remove('_gat')
    Cookies.remove('_gid')
    setIsInitialized(false)
    setIsConsent(false)
  }

  const initialize = () => {
    if (process.env.NEXT_PUBLIC_ANALYTICS_ID && !isInitialized) {
      initGA(process.env.NEXT_PUBLIC_ANALYTICS_ID)
      setIsInitialized(true)
    }
  }

  const pageView = () => {
    isConsent && ReactGA.send('pageview')
  }

  return {
    pageView,
    untrack,
    initialize,
    isConsent
  }
}
