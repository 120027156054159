export const DEVICE_BREAKPOINTS = {
  XXS: 0,
  XS: '30rem',
  SM: '48rem',
  MD: '62rem',
  LG: '75rem',
  XL: '100rem'
}

export const BODY_BREAKPOINTS = {
  LG: '52.5rem',
  XL: '65rem'
}
