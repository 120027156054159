import {useState} from 'react'
import CMP, {CMP_VISIBLE_STATUS} from '../CMP/index.js'
import styles from './Footer.module.css'

export default function Footer() {
  const [visible, setVisible] = useState(CMP_VISIBLE_STATUS.BY_COOKIE_VALUE)
  return (
    <>
      <footer className={styles.Footer}>
        2021 © - Website made by Oriol Puig with Next.js
        <ul>
          <li>
            <a onClick={() => setVisible(CMP_VISIBLE_STATUS.SHOW)}>
              Manage cookies
            </a>
          </li>
        </ul>
      </footer>
      <CMP
        visible={visible}
        onClick={() => setVisible(CMP_VISIBLE_STATUS.HIDDEN)}
      />
    </>
  )
}
