import {css} from 'styled-components'

/* Pallete builder: http://scg.ar-ch.org/ */
export default css`
  :root {
    --op-c-black: #000000;
    --op-c-white: #ffffff;
    --op-c-text: #2b2b2b;

    /* Primary */
    --op-c-primary: #ffb400;

    --op-c-primary-light-1: #ffc333;
    --op-c-primary-light-2: #ffd266;
    --op-c-primary-light-3: #ffe199;
    --op-c-primary-light-4: #fff0cc;

    --op-c-primary-dark-1: #cc9000;
    --op-c-primary-dark-2: #996c00;
    --op-c-primary-dark-3: #664800;
    --op-c-primary-dark-4: #332400;

    /* Secondary */
    --op-c-secondary: #0070f3;

    --op-c-secondary-light-1: #278bff;
    --op-c-secondary-light-2: #5aa6ff;
    --op-c-secondary-light-3: #8dc2ff;
    --op-c-secondary-light-4: #c0ddff;

    --op-c-secondary-dark-1: #0058c0;
    --op-c-secondary-dark-2: #004da6;
    --op-c-secondary-dark-3: #003573;
    --op-c-secondary-dark-4: #001e40;

    /* Gray */
    --op-c-gray: #777777;

    --op-c-gray-light-1: #909090;
    --op-c-gray-light-2: #aaaaaa;
    --op-c-gray-light-3: #c3c3c3;
    --op-c-gray-light-4: #dddddd;

    --op-c-gray-dark-1: #5d5d5d;
    --op-c-gray-dark-2: #444444;
    --op-c-gray-dark-3: #2a2a2a;
    --op-c-gray-dark-4: #111111;

    --op-c-gray-light: #eef0eb; /* TODO: legacy color */

    /* Success */
    --op-c-success: #00a544;

    --op-c-success-light-1: #40bb72;
    --op-c-success-light-2: #80d2a1;
    --op-c-success-light-3: #bfe8d0;
    --op-c-success-light-4: #e5f5eb;

    --op-c-success-dark-1: #008436;
    --op-c-success-dark-2: #006b2c;
    --op-c-success-dark-3: #004a1e;
    --op-c-success-dark-4: #002810;

    /* Warning */
    --op-c-warning: #f79500;

    --op-c-warning-light-1: #f9af40;
    --op-c-warning-light-2: #fbca80;
    --op-c-warning-light-3: #fce4bf;
    --op-c-warning-light-4: #fef4e6;

    --op-c-warning-dark-1: #c57700;
    --op-c-warning-dark-2: #a06000;
    --op-c-warning-dark-3: #6f4300;
    --op-c-warning-dark-4: #3d2500;

    /* error */
    --op-c-error: #e23515;

    --op-c-error-light-1: #e9674f;
    --op-c-error-light-2: #f09a8a;
    --op-c-error-light-3: #f7ccc4;
    --op-c-error-light-4: #fcebe8;

    --op-c-error-dark-1: #b42a10;
    --op-c-error-dark-2: #93220d;
    --op-c-error-dark-3: #651709;
    --op-c-error-dark-4: #380d05;
  }
`
