import {css} from 'styled-components'

export default css`
  :root {
    --op-ff-base: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    /* Font sizes */
    --op-fz-base: 1rem;

    --op-fz-giant: calc(var(--op-fz-base) + 2rem);
    --op-fz-xxxl: calc(var(--op-fz-base) + 1rem);
    --op-fz-xxl: calc(var(--op-fz-base) + 0.75rem);
    --op-fz-xl: calc(var(--op-fz-base) + 0.5rem);
    --op-fz-l: calc(var(--op-fz-base) + 0.25rem);
    --op-fz-m: var(--op-fz-base);
    --op-fz-s: calc(var(--op-fz-base) - 0.125rem);
    --op-fz-xs: calc(var(--op-fz-base) - 0.25rem);
    --op-fz-xxs: calc(var(--op-fz-base) - 0.5rem);

    /* Headings */
    --op-fz-h1: var(--op-fz-giant);
    --op-fz-h2: var(--op-fz-xxxl);
    --op-fz-h3: var(--op-fz-xxl);
    --op-fz-h4: var(--op-fz-xl);
    --op-fz-h5: var(--op-fz-l);
    --op-fz-body: var(--op-fz-m);

    /* Font weights */
    --op-fw-light: 300;
    --op-fw-regular: 400;
    --op-fw-semi-bold: 500;
    --op-fw-bold: 700;

    /* Line heights */
    --op-lh-giant: calc(var(--op-fz-giant) * 1.2);
    --op-lh-xxxl: calc(var(--op-fz-xxxl) * 1.2);
    --op-lh-xxl: calc(var(--op-fz-xxl) * 1.2);
    --op-lh-xl: calc(var(--op-fz-xl) * 1.2);
    --op-lh-l: calc(var(--op-fz-l) * 1.2);
    --op-lh-m: calc(var(--op-fz-m) * 1.2);
    --op-lh-s: calc(var(--op-fz-s) * 1.2);
    --op-lh-xs: calc(var(--op-fz-xs) * 1.2);
    --op-lh-xxs: calc(var(--op-fz-xxs) * 1.2);
  }
`
