import {createGlobalStyle} from 'styled-components'
import {normalize} from '../resets/normalize.js'
import ui from './ui/index.js'

export default createGlobalStyle`
${normalize}
${ui}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--op-ff-base);
  font-size: var(--op-fz-body);
  color: var(--op-c-text);
  background-color: #eee;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
}

a {
  color: var(--op-c-secondary);
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

`
