import {css} from 'styled-components'

export default css`
  :root {
    --op-bdrs-base: 0.5rem;
    --op-bdw-base: 0.5rem;

    --op-bdsh-s: 0 0 0.25rem 0 rgba(0, 0, 0, 0.24);
    --op-bdsh-m: 0 0 0.5rem 0 rgba(0, 0, 0, 0.24);
    --op-bdsh-l: 0 0 1rem 0 rgba(0, 0, 0, 0.16);
    --op-bdsh-focus: 0 0 0.5rem 0;
  }
`
