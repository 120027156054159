import styled from 'styled-components'
import {DEVICE_BREAKPOINTS} from '@/styles/layout/breakpoints.js'

export const UIHeader = styled.header`
  justify-content: center;
  background-color: var(--op-c-white);
  display: flex;
  padding: calc(var(--op-spacing-base) * 4);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 10%) 4px 10px 10px;

  .logo {
    color: var(--op-c-secondary);
    font-family: 'Noteworthy';
    font-size: var(--op-fz-xxxl);
    line-height: var(--op-lh-xxxl);
    text-decoration: none;
  }

  @media (min-width: ${DEVICE_BREAKPOINTS.SM}) {
    align-items: center;
    justify-content: initial;
    height: 96px;
  }
`
