import {css} from 'styled-components'

import boxStyle from './settings/_box-style.js'
import color from './settings/_color.js'
import font from './settings/_font.js'
import spacing from './settings/_spacing.js'

export default css`
  ${color}
  ${font}
  ${spacing}
  ${boxStyle}
`
