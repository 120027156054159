import Script from 'next/script'
import Footer from '../components/common/Footer'
import {Header} from '../components/common/Header'
import GlobalStyles from '@/styles/theme/globals'
import {ThemeProvider} from 'styled-components'

const isProduction = process.env.NODE_ENV === 'production'

function MyApp({Component, pageProps}) {
  const getLayout = Component.getLayout || (page => page)
  return (
    <>
      {isProduction && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_TAG_MANAGER_ID}');  `
          }}
        />
      )}
      {isProduction && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_TAG_MANAGER_ID}`}
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}
          ></iframe>
        </noscript>
      )}
      <ThemeProvider theme={{}}>
        <GlobalStyles />
        <Header />
        {getLayout(<Component {...pageProps} />)}
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default MyApp
