import NextLink from 'next/link'
import {UIHeader} from './styles/ui.js'

export const Header = () => {
  return (
    <UIHeader>
      <NextLink href="/" passHref>
        <a className="logo">Oriol Puig</a>
      </NextLink>
    </UIHeader>
  )
}
