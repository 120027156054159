import {useRouter} from 'next/router'
import {useCallback, useEffect} from 'react'
import CookieConsent from 'react-cookie-consent'

import useTracking from '../../../hooks/useTracking.js'

export const CMP_VISIBLE_STATUS = {
  BY_COOKIE_VALUE: 'byCookieValue',
  SHOW: 'show',
  HIDDEN: 'hidden'
}

export default function CMP({
  visible = CMP_VISIBLE_STATUS.BY_COOKIE_VALUE,
  onClick = () => {}
}) {
  const router = useRouter()
  const tracker = useTracking()

  const handlePageView = useCallback(() => tracker.pageView(), [tracker])

  useEffect(() => {
    router.events.on('routeChangeStart', handlePageView)

    return () => {
      router.events.off('routeChangeStart', handlePageView)
    }
  }, [handlePageView, router])

  const handleAcceptCookie = () => {
    tracker.initialize()
    handlePageView()
    onClick()
  }

  const handleDeclineCookie = () => {
    tracker.untrack()
    onClick()
  }

  return (
    <CookieConsent
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      visible={visible}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  )
}
